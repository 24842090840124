import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/carmen3.png";
import Home2 from "./Home2";
import Type from "./Type";
import {
  AiOutlineMail,
  AiOutlineInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";


function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                ¡Hola!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                Soy <span class="animated-text">
                <Type />
                  </span>
              </h1>
              <h2 className="subheading-name">
                Directora de Casting
              </h2>
              <p className="home-about-body">
              Mi objetivo es ayudarte a encontrar el talento perfecto para tu proyecto. Ya seas un actor buscando nuevas oportunidades, un productor en busca del reparto ideal o un director con una visión única, estoy aquí para colaborar contigo. Trabajemos juntos para crear proyectos excepcionales. ¡No dudes en contactarme!            
            </p>
              <Col md={12} className="home-about-social">
            <ul className="home-about-social-links">
              <Col>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/carmeona/?hl=es"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineInstagram />
                </a>
                <a 
                href="https://www.instagram.com/carmeona/?hl=es"
                target="_blank"
                rel="noreferrer"
                 className="social-name">
                  @carmeona
                </a>
              </li>
              </Col>
              <Col>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/carmeona/?originalSubdomain=es"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
                <a 
                href="https://www.linkedin.com/in/carmeona/?originalSubdomain=es"
                target="_blank"
                rel="noreferrer"
                 className="social-name">
                  Carmen Ardana
                </a>
              </li>
              </Col>
             
              <li className="social-icons">
                <a
                  href="mailto: casting@carmeona.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
                <a 
                href="mailto: casting@carmeona.com"
                target="_blank"
                rel="noreferrer"
                 className="social-name">
                  casting@carmeona.com
                </a>
              </li>
             
            </ul>
          </Col>
            </Col>

            <Col md={5} style={{ paddingBottom: 0 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
            <Row>
          
        </Row>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
